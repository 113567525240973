import React from "react";
import { Link } from "gatsby";
import { cn } from "../lib/helpers";
import Seo from "../components/seo";
import Layout from "../components/layout";
import Container from "../components/container";

import * as styles from "../styles/components/404.module.css";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Page Not found" />
    <div className={styles.root}>
      <Container>
        <h1 className={styles.title}>404</h1>
        <div className={cn("entry-content", styles.content)}>
          <p>The page may have been moved or has been removed. Please <Link to="/get-in-touch">contact us</Link> if you have any questions or return to our <Link to="/">homepage</Link> to start over.</p>
        </div>
      </Container>
    </div>
  </Layout>
);

export default NotFoundPage;
